import React from 'react';
import Navbar from './components/Navbar/Navbar';
// import Content from './components/Content/Content';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
        <Navbar />
        {/* <Content /> */}
        <Body />
        <Footer />
    </div>
  );
}

export default App;
